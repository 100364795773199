<main>

    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>About</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-xl-7 col-lg-7">
                    <div class="trusted-img">
                        <img src="assets/img/team/wetrusted.jpg" alt="">
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <div class="trusted-caption">
                       <h2>About Us</h2>
                       <p>Established in 2016, Dawar Insurance Agency started off as a Life and Health Insurance agency. About four months ago, we started selling personal and commercial products. When our agency started selling personal and commercial insurance products, we went from being individual insurance agents selling life and health insurance to become Dawar Insurance Agency. Our agency specializes in auto, home, business, commercial trucking, health, and life insurance.</p>
                        <p>Call us or contact us today to see how we can assist you with your insurance needs.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-6">
                    <div class="single-profile mb-30">
                        <!-- Front -->
                       <h2>An Independent Advantage</h2>
                       <p>We are Independent Agents in Fresno, California, free to choose the best carrier for your insurance needs. We do not work for an insurance company; we work for you. We work on your side when you have a loss and follow through to see that you get fair, prompt payment and service. Dawar Insurance Agency represents a carefully selected group of financially strong, reputable insurance companies. Therefore, we are able to offer you the best coverage at the most competitive price.</p>
                     </div>
                </div>
               
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="single-profile mb-30">
                        <!-- Back -->
                        <div class="single-profile-back-last">
                            <h2>Associations</h2>
                            <p>Community Associations</p>
                            <ul style="list-style-type: circle;">
                                <li> <a href="https://clovischamber.com/">Clovis Chamber of Commerce </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Team-profile End-->
  
    <!-- Testimonial Start -->
    <div class="testimonial-area fix text-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-center m-auto">
                    <h2>Testimonials</h2>
                    <div id="myCarousel" class="carousel slide" data-ride="carousel">
                        <!-- Carousel indicators -->
                        <ol class="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>   
                        <!-- Wrapper for carousel items -->
                        <div class="carousel-inner">
                            <div class="item carousel-item active">
                                <div class="img-box"><img src="assets/img/testimonials.png" alt="" witdh="100" height="100"></div>
                                <p class="testimonial">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu sem tempor, varius quam at, luctus dui. Mauris magna metus, dapibus nec turpis vel, semper malesuada ante. Idac bibendum scelerisque non non purus. Suspendisse varius nibh non aliquet.</p>
                                <p class="overview"><b>Paula Wilson</b>, Media Analyst</p>
                            </div>
                            <div class="item carousel-item">
                                <div class="img-box"><img src="assets/img/testimonials.png" alt="" witdh="100" height="100"></div>
                                <p class="testimonial">Vestibulum quis quam ut magna consequat faucibus. Pellentesque eget nisi a mi suscipit tincidunt. Utmtc tempus dictum risus. Pellentesque viverra sagittis quam at mattis. Suspendisse potenti. Aliquam sit amet gravida nibh, facilisis gravida odio.</p>
                                <p class="overview"><b>Antonio Moreno</b>, Web Developer</p>
                            </div>
                            <div class="item carousel-item">
                                <div class="img-box"><img src="assets/img/testimonials.png" alt="" witdh="100" height="100"></div>
                                <p class="testimonial">Phasellus vitae suscipit justo. Mauris pharetra feugiat ante id lacinia. Etiam faucibus mauris id tempor egestas. Duis luctus turpis at accumsan tincidunt. Phasellus risus risus, volutpat vel tellus ac, tincidunt fringilla massa. Etiam hendrerit dolor eget rutrum.</p>
                                <p class="overview"><b>Michael Holz</b>, Seo Analyst</p>
                            </div>
                        </div>
                        <!-- Carousel controls --><br>
                        <a class="carousel-control left carousel-control-prev" href="#myCarousel" data-slide="prev">
                            <i class="fa fa-angle-left"></i>
                        </a>
                        <a class="carousel-control right carousel-control-next" href="#myCarousel" data-slide="next">
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Testimonial End -->

    <!-- Recent Area Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-6">
                    <div class="single-profile mb-30">
                        <!-- Front -->
                      <h2>What is an Independent Insurance Agent?</h2>
                       <p>When you decide to buy a car, you wouldn't purchase the first one you see. What if one day the automobile industry decided to make only one type of car, one make and one model. You wouldn't have a choice!

                        The same situation holds true for insurance. You need insurance to drive a car, to purchase a house, to protect your family's financial future and to run a small business. But if there was only one insurance company that offered only one type of insurance, you wouldn't have a choice. The only solution would be going to that one company.
                        
                        With an independent insurance agent, you have choices. Independent agents are not tied to any one insurance company. One of the advantages of using an independent agent is that he or she works to satisfy your needs. You are using an expert for an important financial decision.
                        
                        An independent agent has several companies that he or she can approach to get you the best coverage at the best price. And your independent agent will know the companies with the most efficient claims departments to recover your losses as quickly as possible.
                        
                        When you buy insurance, you want an advocate who will properly assess the risks you face and give you an objective analysis of the marketplace, because it's up to you to make the final choice.</p>
                    </div>
                </div>
               
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="single-profile mb-30">
                        <!-- Back -->
                      
                            <img src="assets/img/recent/rcent_1.png" alt="">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Recent Area End-->

    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                       
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>