<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Group Benefits</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-xl-7 col-lg-7">
                    <div class="trusted-img">
                        <img src="assets/img/Group-benefits.jpg" alt="">
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <div class="trusted-caption">
                       <h2>Group Benefits</h2>
                      <p>
                        Dawar Insurance Agency offers a complete line of insurance and group benefits for employers ranging from group health insurance to life and FSAs. Many businesses today face challenges in attracting and retaining top employees. As a business owner, you know the importance of employee benefits and their contribution to your business success. We will work with you to develop a program tailored to your individual circumstances.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <h2>These products and services are designed to provide solutions to your personal and business financial needs, including:</h2>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="single-profile mb-30">
                    <ul style="list-style-type: circle;line-height: 35px;">
                      <li><b style="font-size: 40px;">.</b> &nbsp;401(k) Retirement Plans</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Buy / Sell Protection </li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Flexible Spending Accounts (FSA)</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Group Dental Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Group Disability Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Group Health Insurance</li>
                   
                    </ul>
                    </div>
                </div>
               
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="single-profile mb-30">
                        <ul style="list-style-type: circle;line-height: 35px;">
                            <li><b style="font-size: 40px;">.</b> &nbsp;Group Life Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Group Long-Term Care (LTC) Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Group Vision Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Health Reimbursement Arrangement</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Health Savings Accounts (HSA) </li>
                    
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Team-profile End-->
  
  
    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1" class="nice-select">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>
