<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Auto Insurance</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-xl-6 col-lg-6">
                    <div class="trusted-img">
                        <img src="assets/img/Auto_Insurance.jpg" alt="Auto Insurance" >
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="trusted-caption">
                       <h2>Auto Insurance</h2>
                      <p style="margin-bottom:10px;">
                        Auto or car insurance coverage has two parts. The first is the liability section of the policy. It covers your financial responsibility for injuring others. Some liability coverage is required by most states.

                     The second part of auto insurance covers the car itself: comprehensive coverage reimburses losses from fire, theft or other perils; collision coverage pays to repair losses caused by an accident. Often this coverage is mandated by leasing companies or banks. There are also ancillary medical, car rental and other coverages which vary by state.
                        
                        Utilizing high deductibles on the physical damage coverages can help reduce premiums. If you carry umbrella insurance, you must be sure that you carry the required amount of basic liability insurance to avoid a gap in coverage for a serious accident.   
                    </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <h3>Dawar Insurance Agency offers free, comparative quotes on Auto Insurance from multiple insurance carriers so you can get the best possible rate.

              <br> <br> Want to see how much we can save you? Just request a quote to find out.</h3>
         
        </div>
    </div>
    <!-- Team-profile End-->
  
  
    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1" class="nice-select">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>
