<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Business Insurance</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row">
            
                <div class="col-xl-7 col-lg-7">
                    <div>
                       <h2>Business Insurance</h2>
                      <p>
                        As a business owner, you have enough on your mind without having to worry about your insurance needs. You want to have the confidence that the business you've built is covered by a company that understands your business insurance needs.

                       <br> Commercial business insurance provides wide-ranging protection for businesses and their subsidiaries under a single policy. There are various types of commercial business insurance that take care of property, liability and workers' compensation, to name a few. While property insurance covers damages to business property and products stored within the premises; liability insurance provides coverage for damages to third parties; and workers' compensation insurance covers injuries to employees.
                        
                        <br>Commercial business insurance is an important aspect of any business that cannot be ignored especially if the business premises are leased or rented and even otherwise. Whatever your commercial insurance needs, we can design a protection program that's right for you.   
                    </p>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <div class="trusted-img">
                        <img src="assets/img/Business_Insurance.jpg" alt="Business Insurance">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <h2>Dawar Insurance Agency provides the following types of business insurance protection:</h2>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="single-profile mb-30">
                    <ul style="list-style-type: circle;line-height: 35px;">
                      <li><b style="font-size: 40px;">.</b> &nbsp;Builders' Risk Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Business Interruption Insurance </li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Business Owners Package Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Cargo Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Commercial Auto Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Commercial Earthquake Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Commercial Property Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Commercial Trucking Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Commercial Umbrella Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Contractor's General Liability Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Cyber Liability Insurance</li>
                        <li><b style="font-size: 40px;">.</b> &nbsp;Directors and Officers Liability Insurance</li>
                        <li><b style="font-size: 40px;">.</b> &nbsp;Employment Practices Liability Insurance (EPLI)</li>
                        <li><b style="font-size: 40px;">.</b> &nbsp;Farm / Agribusiness Insurance</li>
                        <li><b style="font-size: 40px;">.</b> &nbsp;Garage Insurance</li>
                        <li><b style="font-size: 40px;">.</b> &nbsp;General Liability Insurance</li>
                    </ul>
                    </div>
                </div>
               
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="single-profile mb-30">
                        <ul style="list-style-type: circle;line-height: 35px;">
                            <li><b style="font-size: 40px;">.</b> &nbsp;Hotel & Motel Hospitality Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Key Person / Employee Insurance </li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Kidnap & Ransom Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Liquor Liability Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Manufacturers Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Non-Profit Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Product Liability Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Professional Liability (Errors & Omissions) Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Restaurant & Bar Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Special Event Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Restaurant & Bar Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Surety Bonds</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Technology Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Vineyard & Winery Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Wholesalers & Distributors Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Workers' Compensation Insurance</li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Team-profile End-->
  
  
    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1" class="nice-select">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>
