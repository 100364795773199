import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-health-insurance',
  templateUrl: './family-health-insurance.component.html',
  styleUrls: ['./family-health-insurance.component.css']
})
export class FamilyHealthInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
