<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Homeowners Insurance</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-xl-6 col-lg-6">
                    <div class="trusted-img">
                        <img src="assets/img/Homeowner-insurance.jpg" alt="Homeowners Insurance">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="trusted-caption">
                       <h2>Homeowners Insurance</h2>
                      <p style="margin-bottom:10px;">
                        If you have tangible assets, you need the protection of a homeowners insurance policy. These policies cover you in a home or an apartment, whether you are an owner or a renter. A well-written homeowners policy will pay to replace any of your personal property that is destroyed in a fire or other disaster. The policy will also be your first line of defense against a lawsuit from someone injured at your home.

                      <br>  The cost of this coverage is determined by many rating factors. The quality of the coverage, however, is determined by the quality of the insurer and whether the policy is written on a named perils or all-risk basis. A named-perils policy covers only those losses specifically cited in the contract. The all-risk policy works the opposite way - unless a peril is specifically excluded, coverage is provided. The all-risk policy is broader and the burden of proof is on the carrier, not you, in the event of a loss.   </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <h3>Dawar Insurance Agency offers free, comparative quotes on Homeowners Insurance from multiple insurance carriers so you can get the best possible rate.

              <br> <br> Want to see how much we can save you? Just request a quote to find out.</h3>
         
        </div>
    </div>
    <!-- Team-profile End-->
  
  
    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1" class="nice-select">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>
