<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Individual Life Insurance</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-xl-6 col-lg-6">
                    <div class="trusted-img">
                        <img src="assets/img/IndividualLife-Insurance.jpg" alt="Individual Life Insurance">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="trusted-caption">
                       <h2>Individual Life Insurance</h2>
                      <p style="margin-bottom:10px;">
                        Life insurance from Dawar Insurance Agency can help you secure your family's financial future by providing the funds they need to: cover burial expenses, uninsured medical bills, pay off your mortgage and other outstanding debts, and maintain a comfortable standard of living.

                       <br> There are a variety of life insurance policies that we can provide. The kind of policy you choose depends on your needs:  </p>
                    </div>
                </div>

              
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12">
                    <div class="trusted-caption">
                       <h2>Term Life Insurance</h2>
                      <p style="margin-bottom:10px;">
                        Term life insurance is a low-cost way of providing maximum coverage for your family. Protection is provided for a limited number of years. The insurance expires without value if the insured lives beyond the policy period, usually 5 to 20 years. Other policy life periods are available, including one-year annual renewable term.

                        <br>Term insurance premiums will not increase during the guaranteed policy time period (term) you select. Term life insurance pays a death benefit only if you die during that term. Term insurance generally provides the largest insurance protection for your premium dollar.

                          <br>Term life Insurance remains in force for as long as premiums are current, provided there are no misrepresentations on the application. The insurance coverage terminates if you discontinue your premium payments.</p>
                  
                          <h2>Universal Life Insurance</h2>
                          <p style="margin-bottom:10px;">
                            Universal life insurance is characterized by great flexibility. Policyholders can determine the amount and frequency of premium payments (i.e. the more you pay, the less time you will need to pay). Your premiums cover the insurance part also the savings or investment element and the expense part. The stated interest on the investment portion changes along with movement in interest rates; moves in 1/4 % interest steps are typical as banks and other financial institutions make similar moves. </p>
                            <h2>Whole Life Insurance</h2>
                            <p style="margin-bottom:10px;">
                              Whole life insurance provides permanent protection for the whole of life, from the date of policy issue to the date of the insured's death, provided that premiums are paid. Premiums are set at the time of policy issue and remain level for the policy's life. Unlike term insurance, whole life combines insurance protection and savings or cash value which builds over time. Cash value build-up may provide a source for living benefits, for example, helping pay off a mortgage, or a child's education, or cash surrender value if the policy is ever canceled.
      
                              These products are continually changing, and we can provide you with the latest information and policies available!</p>
                         
                  
              </div>
                </div>
              
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <h3>Dawar Insurance Agency offers free, comparative quotes on Individual Life Insurance from multiple insurance carriers so you can get the best possible rate.
              <br> <br> Want to see how much we can save you? Just request a quote to find out.</h3>
         
        </div>
    </div>
    <!-- Team-profile End-->
  
  
    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1" class="nice-select">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>
