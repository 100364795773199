import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixed-annuities',
  templateUrl: './fixed-annuities.component.html',
  styleUrls: ['./fixed-annuities.component.css']
})
export class FixedAnnuitiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
