<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Home Insurance</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-xl-7 col-lg-7">
                    <div class="trusted-img">
                        <img src="assets/img/Auto-Home-Insurance.jpg" alt="Auto, Home, and Personal Insurance">
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <div class="trusted-caption">
                       <h2>Auto, Home, and Personal Insurance</h2>
                      <p>
                        At Dawar Insurance Agency, our personal insurance products provide our customers with protection for their homes, automobiles and personal possessions, along with personal liability coverages.
                      <br>How we serve you is as important as what we provide. First, we listen to you, then we discuss your needs and answer your questions. Next, we look at both your short-term and long-term objectives and help put your insurance and planning needs in perspective.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <h2>Dawar Insurance Agency provides the following types of personal insurance protection:</h2>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="single-profile mb-30">
                    <ul style="list-style-type: circle;line-height: 35px;">
                      <li><b style="font-size: 40px;">.</b> &nbsp;ATV Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Auto Insurance </li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Boat & Marine Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Collector Car Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Condominium Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Earthquake Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Flood Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;High Net Worth Coverage</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Homeowners Insurance</li>
                      <li><b style="font-size: 40px;">.</b> &nbsp;Mobile (Manufactured) Home Insurance</li>
                    </ul>
                    </div>
                </div>
               
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="single-profile mb-30">
                        <ul style="list-style-type: circle;line-height: 35px;">
                            <li><b style="font-size: 40px;">.</b> &nbsp;Motor Home / RV Insurance </li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Motorcycle Insurance </li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Personal Umbrella Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Personal Watercraft Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Pet Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Rental Property Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Renters Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Snowmobile Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Travel Insurance</li>
                            <li><b style="font-size: 40px;">.</b> &nbsp;Valuable Items Insurance</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Team-profile End-->
  
  
    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1" class="nice-select">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>
