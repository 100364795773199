<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Fixed Annuities</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
            
                <div class="col-xl-7 col-lg-7">
                    <div>
                       <h2>Fixed Annuities</h2>
                      <p>
                        A fixed annuity can help you accumulate tax-deferred earnings as part of your overall retirement plan. Annuities offer the opportunity for lifetime payments and tax-deferred earnings and provide a guaranteed death benefit for your beneficiaries. All guarantees are backed by the continued claims-paying ability of the issuing insurance company. You may want to consider investing in an annuity as part of your long-term financial strategy if:
                      </p>
                      <ul style="list-style-type: circle;line-height: 35px;">
                        <li><b style="font-size: 40px;">.</b> &nbsp; You're in a higher tax bracket and want to defer additional income.</li>
                        <li><b style="font-size: 40px;">.</b> &nbsp; You've reached your deductible limit on all your retirement accounts and wish to save more for retirement.</li>
                      </ul><br>
                      <p>  An annuity is different from most other retirement savings vehicles. It's actually a contract between you and an insurance company. In return for making one or more premium payments, the insurance company agrees to provide you an income stream, usually during retirement. You can elect to receive payment all at once or as a series of payments, even for the rest of your life.   </p>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <div class="trusted-img">
                        <img src="assets/img/Fixed-Annuities.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

  <!-- Team-profile Start -->
  <div class="team-profile team-padding">
    <div class="container">
        <h3>Dawar Insurance Agency offers free, comparative quotes on Auto Insurance from multiple insurance carriers so you can get the best possible rate.

          <br> <br> Want to see how much we can save you? Just request a quote to find out.</h3>
     
    </div>
</div>
<!-- Team-profile End-->


<!-- Request Back Start -->
<div class="request-back-area section-padding30">
    <div class="container">
        <div class="row d-flex justify-content-between">
            <div class="col-xl-4 col-lg-5 col-md-5">
                <div class="request-content">
                    <h3>Start Saving Today</h3>
                    <p>We will get back to you within 24 hrs.</p>
                </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7">
                <!-- Contact form Start -->
                <div class="form-wrapper">
                    <form id="contact-form" action="#" method="POST">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-box  mb-30">
                                    <input type="text" name="name" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-box mb-30">
                                    <input type="phone" name="email" placeholder="Phone">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-box mb-30">
                                    <input type="email" name="email" placeholder="email">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-box mb-30">
                                    <select name="select" id="select1" class="nice-select">
                                        <option value="">Type of Insurance</option>
                                        <option value="">Auto Insurance</option>
                                        <option value="">Home Insurance</option>
                                        <option value="">Othe Personal Insurance</option>
                                        <option value="">Business Insurance</option>
                                        <option value="">Life Insurance</option>
                                        <option value="">Health Insurance</option>
                                        <option value="">Group Benefits</option>                                          
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <button type="submit" class="send-btn">Send</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>     <!-- Contact form End -->
        </div>
    </div>
</div>
<!-- Request Back End -->
</main>
