<header> 
   <nav class="navbar navbar-default navbar-expand-lg navbar-light fixed-top" style="background-color: white;">
    <div class="container-fluid fixed-top" style="background-color: #002D5B; color: white;height: 50px;">
       <div class="col-8 pl-5 p-1">
        <i class="fa fa-phone"></i>&nbsp; Call Us Today &nbsp;
        <a href="tel:(559) 538-0882" class="text-white" style="font-size: 16px;">(559) 538-0882 </a>
       </div>
       <div class="col-4 pr-5 text-right">
        <i class="fa fa-facebook"></i>&nbsp;
        <i class="fa fa-instagram"></i>&nbsp;
        <i class="fa fa-twitter"></i>
       </div>
    </div>
    <div class="navbar-header d-flex col mt-30 text-center" style="margin-top: 60px;">
      <a class="navbar-brand" [routerLink]="['/']" style="color: #002D5B;font-weight: 800; font-size: 25px;">Dawar Insurance Agency</a>
      <button type="button" data-target="#navbarCollapse" data-toggle="collapse" class="navbar-toggle navbar-toggler ml-auto">
        <span class="navbar-toggler-icon"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
  
    <div id="navbarCollapse" class="collapse navbar-collapse justify-content-start text-center">
      <ul class="nav navbar-nav mr-auto" style="margin-top: 50px;">
        
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle pl-4" href="#" id="navbardrop" data-toggle="dropdown" style="color: black;">
            Auto, Home and Personal Insurance
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/auto_insurance']" style="color: black;">Auto Insurance</a>
            <a class="dropdown-item" [routerLink]="['/homeowner_insurance']" style="color: black;">Homeowner Insurance</a>
            <a class="dropdown-item" [routerLink]="['/renters_insurance']" style="color: black;">Renters Insurance</a>
            <a class="dropdown-item" [routerLink]="['/motorcycle_insurance']" style="color: black;">Motorcycle Insurance</a>
            <a class="dropdown-item" [routerLink]="['/boat_marine']" style="color: black;">Boat & Marine Insurance</a>
          
          </div>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle pl-4" [routerLink]="['/business_insurance']" id="navbardrop" data-toggle="dropdown" style="color: black;">
              Business Insurance
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" [routerLink]="['/businessowner_package']" style="color: black;">Business Owner Package Insurance</a>
            <a class="dropdown-item" [routerLink]="['/commercial_auto']" style="color: black;">Commercial Insurance</a>
            <a class="dropdown-item" [routerLink]="['/commercial_property']" style="color: black;">Commercial Property Insurance</a>
            <a class="dropdown-item" [routerLink]="['/general_liability']" style="color: black;">General Liability Insurance</a>
            <a class="dropdown-item" [routerLink]="['/workers_compensation']" style="color: black;">Worker's Compensation Insurance</a>
              
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle pl-4" [routerLink]="['/life_insurance']" id="navbardrop" data-toggle="dropdown" style="color: black;">
              life Insurance
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" [routerLink]="['/fixed_annuities']" style="color: black;">Fixed Annuities</a>
              <a class="dropdown-item" [routerLink]="['/final_expense_insurance']" style="color: black;">Final Expense Insurance</a>
              <a class="dropdown-item" [routerLink]="['/individual_life_insurance']" style="color: black;">Individual Life Insurance</a>
              <a class="dropdown-item" [routerLink]="['/mortgage_protection_insurance']" style="color: black;">Mortgage Protection Insurance</a>
                
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle pl-4" [routerLink]="['/health_insurance']" id="navbardrop" data-toggle="dropdown" style="color: black;">
              Health Insurance
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" [routerLink]="['/individual_disability_insurance']" style="color: black;">Individual Disability Insurance</a>
              <a class="dropdown-item" [routerLink]="['/individual_dental_insurance']" style="color: black;">Individual Dental Insurance</a>
              <a class="dropdown-item" [routerLink]="['/family_health_insurance']" style="color: black;">Individual & Family Health Insurance</a>
              <a class="dropdown-item" [routerLink]="['/long_term_care']" style="color: black;">Individual Long-Term Care</a>
              <a class="dropdown-item" [routerLink]="['/individual_vision_insurance']" style="color: black;">Individual Vision Insurance</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle pl-4" [routerLink]="['/group_benefits']" id="navbardrop" data-toggle="dropdown" style="color: black;">
              Group Benefits
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" [routerLink]="['/group_disability_insurance']" style="color: black;">Group Disability Insurance</a>
              <a class="dropdown-item" [routerLink]="['/group_dental_insurance']" style="color: black;">Group Dental Insurance</a>
              <a class="dropdown-item" [routerLink]="['/group_life_insurance']" style="color: black;">Group Life Insurance</a>
              <a class="dropdown-item" [routerLink]="['/group_long_term_insurance']" style="color: black;">Group Long-Term Care</a>
              <a class="dropdown-item" [routerLink]="['/group_health_insurance']" style="color: black;">Group Health Insurance</a>
            </div>
          </li>
       
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle pl-4" href="#" id="navbardrop" data-toggle="dropdown" style="color: black;" [routerLink]="['/contact']">
              Contact
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" [routerLink]="['/secure_contact_form']" class="nav-link pl-4" style="color: black;">Secure Contact Form </a>
              <a class="dropdown-item" [routerLink]="['/refer_friend']" class="nav-link pl-4" style="color: black;">Refer a Friend</a>
            </div>
          </li>

      </ul>   
    </div>
  </nav>
</header>
