import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-businessowner-insurance',
  templateUrl: './businessowner-insurance.component.html',
  styleUrls: ['./businessowner-insurance.component.css']
})
export class BusinessownerInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
