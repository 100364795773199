
    <main>
 <div class="slider-area ">
            <div class="slider-active"> 
                <div class="single-slider slider-height d-flex align-items-center" data-background="assets/img/hero/h1_hero.jpg" style="background-image: url(assets/img/hero/h1_hero.jpg);margin-top: 120px;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-8">
                                <div class="hero__caption">
                                    <p data-animation="fadeInLeft" data-delay=".4s">Welcome to Dawar Insurance Agency</p>
                                    <h1 data-animation="fadeInLeft" data-delay=".6s" style="font-size: 48px;">Your Local Fresno Independent Insurance Agency</h1>
                                   
                                    <div class="hero__btn" data-animation="fadeInLeft" data-delay=".8s">
                                        <a href="industries.html" class="btn hero-btn">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
        <!-- slider Area End-->

        <!-- Team-profile Start -->
        <div class="team-profile team-padding">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/Truck-insurance.jpg" alt="">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">Trucking Insurance</a></h4>
                                    <p>Whether you’re working in construction, excavation, manufacturing, or any other private carrier capacity, we have truck insurance solutions for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/team/team_2.jpg" alt="">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">Life Insurance</a></h4>
                                    <p>Whether you’re working in construction, excavation, manufacturing, or any other private carrier capacity, we have truck insurance solutions for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Back -->
                            <div class="single-profile-back-last">
                                <h2>What we do for you</h2>
                                <p>Dawar Insurance Agency specializes in auto, home, business, commercial trucking, health, and life insurance.</p>
                                <a [routerLink]="['/about']">View profile »</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Team-profile End-->

        <!-- We Trusted Start-->
        <div class="we-trusted-area trusted-padding">
            <div class="container">
                <div class="row d-flex align-items-end">
                    <div class="col-xl-7 col-lg-7">
                        <div class="trusted-img">
                            <img src="assets/img/team/wetrusted.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5">
                        <div class="trusted-caption">
                           <h2>Dawar Insurance Agency</h2>
                           <p>Established in 2016, Dawar Insurance Agency started off as a Life and Health Insurance agency. About four months ago, we started selling personal and commercial products. When our agency started selling personal and commercial insurance products, we went from being individual insurance agents selling life and health insurance to become Dawar Insurance Agency. Our agency specializes in auto, home, business, commercial trucking, health, and life insurance.
                           </p>
                            <a href="#" class="btn trusted-btn">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- We Trusted End-->

        <!-- services Area Start-->
       
        <!-- services Area End-->
        <div class="team-profile team-padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle text-center">
                            <h2>Our Services</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/Auto-insurance.jpg" alt="Auto Insurance">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">Auto Insurance</a></h4>
                                    <p>Auto or car insurance coverage has two parts. The first is the liability section of the policy. The second part of auto insurance covers the car itself.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/Home-insurance.jpg" alt="Home Insurance">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">Home Insurance</a></h4>
                                    <p>If you have tangible assets, you need the protection of a homeowners insurance policy. These policies cover you in a home or an apartment, whether you are an owner or a renter. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/Truck-insurance.jpg" alt="">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">Trucking Insurance</a></h4>
                                    <p>Whether you’re working in construction, excavation, manufacturing, or any other private carrier capacity, we have truck insurance solutions for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/Business-insurance.jpg" alt="">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">Business Insurance</a></h4>
                                    <p>There are various types of commercial business insurance that take care of property, liability and workers' compensation, to name a few.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/Health-insurance.jpg" alt="Health Insurance">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">Health Insurance</a></h4>
                                    <p>Dawar Insurance Agency offers a complete line of individual health insurance products that are crafted to meet you and your family’s specific needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="assets/img/General-Liability.jpg" alt="General Liability Insurance">
                                </div>
                                <div class="profile-caption">
                                    <h4><a href="#">General Liability Insurance</a></h4>
                                    <p>General liability insurance is intended to guard operators and owners of companies against claims that may come from a broad spectrum of sources. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Completed Cases Start -->
    
        <!-- Completed Cases end -->

        <!-- Testimonial Start -->
        <div class="testimonial-area fix text-center">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-center m-auto">
                        <h2>Testimonials</h2>
                        <div id="myCarousel" class="carousel slide" data-ride="carousel">
                      
                            <ol class="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                                <li data-target="#myCarousel" data-slide-to="1"></li>
                                <li data-target="#myCarousel" data-slide-to="2"></li>
                            </ol>   
                          
                            <div class="carousel-inner">
                                <div class="item carousel-item active">
                                    <div class="img-box"><img src="assets/img/testimonials.png" alt="" witdh="100" height="100"></div>
                                    <p class="testimonial">Great Work</p>
                                    <p class="overview"><b>Paula Wilson</b></p>
                                </div>
                                <div class="item carousel-item">
                                    <div class="img-box"><img src="assets/img/testimonials.png" alt="" witdh="100" height="100"></div>
                                    <p class="testimonial">Satisifed Services</p>
                                    <p class="overview"><b>Antonio Moreno</b></p>
                                </div>
                                <div class="item carousel-item">
                                    <div class="img-box"><img src="assets/img/testimonials.png" alt="" witdh="100" height="100"></div>
                                    <p class="testimonial">100% Satisifed</p>
                                    <p class="overview"><b>Michael Holz</b></p>
                                </div>
                            </div>
                           <br>
                            <a class="carousel-control left carousel-control-prev" href="#myCarousel" data-slide="prev">
                                <i class="fa fa-angle-left"></i>
                            </a>
                            <a class="carousel-control right carousel-control-next" href="#myCarousel" data-slide="next">
                                <i class="fa fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Testimonial End -->


        <!-- Request Back Start -->
        <div class="request-back-area section-padding30">
            <div class="container">
                <div class="row d-flex justify-content-between">
                    <div class="col-xl-4 col-lg-5 col-md-5">
                        <div class="request-content">
                            <h3>Request for  Call Back</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore,</p>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7">
                        <!-- Contact form Start -->
                        <div class="form-wrapper">
                            <form id="contact-form" action="#" method="POST">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box  mb-30">
                                            <input type="text" name="name" placeholder="Name">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box mb-30">
                                            <input type="phone" name="email" placeholder="Phone">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box mb-30">
                                            <input type="email" name="email" placeholder="email">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box mb-30">
                                            <select name="select" id="select1" class="nice-select">
                                                <option value="">Type of Insurance</option>
                                                <option value="">Auto Insurance</option>
                                                <option value="">Home Insurance</option>
                                                <option value="">Othe Personal Insurance</option>
                                                <option value="">Business Insurance</option>
                                                <option value="">Life Insurance</option>
                                                <option value="">Health Insurance</option>
                                                <option value="">Group Benefits</option>                                          
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <button type="submit" class="send-btn">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>     <!-- Contact form End -->
                </div>
            </div>
        </div>
        <!-- Request Back End -->

    </main>