<footer>
    <!-- Footer Start-->
    <div class="footer-area footer-padding">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                   <div class="single-footer-caption mb-50">
                     <div class="single-footer-caption mb-30">
                          <!-- logo -->
                         <div class="footer-logo">
                             <h2>Dawar Insurance Agency</h2>
                         </div>
                         <div class="footer-tittle">
                             <div class="footer-pera">
                                 <p>Dawar Insurance Agency started off as a Life and Health Insurance agency. </p>
                            </div>
                         </div>
                         <!-- social -->
                         <div class="footer-social">
                             <a href="#"><i class="fab fa-facebook-square"></i></a>
                             <a href="#"><i class="fab fa-twitter-square"></i></a>
                             <a href="#"><i class="fab fa-linkedin"></i></a>
                             <a href="#"><i class="fab fa-pinterest-square"></i></a>
                         </div>
                     </div>
                   </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-5">
                    <div class="single-footer-caption mb-50">
                        <div class="footer-tittle">
                            <h4>Policy Services</h4>
                            <ul>
                                <li><a href="#">Policy Service Center</a></li>
                                <li><a href="#">Online Billing & Payments</a></li>
                                <li><a href="#">File A Claim</a></li>
                                <li><a href="#">Policy Change Request</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-7">
                    <div class="single-footer-caption mb-50">
                        <div class="footer-tittle">
                            <h4>About</h4>
                            <ul>
                                <li><a [routerLink]="['/about']"> Auto Us</a></li>
                                <li><a [routerLink]="['/insurance_carriers']">Our Insurance Carriers</a></li>
                                <li><a [routerLink]="['/contact']">Contact Us</a></li>
                                <li><a [routerLink]="['/secure_contact_form']">Secure Contact Form</a></li>
                                <li><a [routerLink]="['/refer_friend']">Refer a Friend</a></li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                    <div class="single-footer-caption mb-50">
                        <div class="footer-tittle">
                            <h4>Get in Touch</h4>
                            <ul>
                             <li><a href="tel:(559) 538-0882">(559) 538-0882</a>,<a href="tel:(559) 478-1329">(559) 478-1329</a></li>
                             <li><a href="mailto:info@dawarinsurance.com"> info@dawarinsurance.com</a></li>
                             <li><a href="#">2926 North West Ave Suite A
                                Fresno, California 93705</a></li>
                         </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer-bottom aera -->
    <div class="footer-bottom-area footer-bg">
        <div class="container">
            <div class="footer-border">
                 <div class="row d-flex align-items-center">
                     <div class="col-xl-12 ">
                         <div class="footer-copy-right text-center">
                             <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
Copyright &copy;<script>document.write(new Date().getFullYear());</script>dawarinsurance. All rights reserved | Design & Developed by <a href="http://digitalsamay.com/" target="_blank">Geoms Digital House Pvt. Ltd.</a>
<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></p>
                         </div>
                     </div>
                 </div>
            </div>
        </div>
    </div>
    <!-- Footer End-->
</footer>
