import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-marin-insurance',
  templateUrl: './boat-marin-insurance.component.html',
  styleUrls: ['./boat-marin-insurance.component.css']
})
export class BoatMarinInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
