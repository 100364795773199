<main>
    <!-- slider Area Start-->
    <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/contact_hero.jpg" style="background-image: url(assets/img/hero/contact_hero.jpg);margin-top: 120px;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>Individual Disability Insurance</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area trusted-padding">
        <div class="container">
            <div class="row d-flex align-items-end">
            
                <div class="col-xl-7 col-lg-7">
                    <div>
                       <h2>Individual Disability Insurance</h2>
                      <p>
                        Individual Disability Income Protection is a must for a business owner, and highly recommended for executives. If you are a business owner, you should consider purchasing both group and individual policies, if possible. As an executive, you should be sure to obtain group coverage if it is available. Only forty-three percent of large companies provide group long term disability insurance.

                     <br>   Your chances are even less likely to get long term disability insurance if you work for a small company. The cost of a plan provided by your employer is usually less than you would pay for an individual disability policy and often you can get coverage automatically without having to qualify medically. If your employer doesn't provide disability insurance then you may want to consider an individual disability policy.
                        
                        <br>Or, you may need a personal disability insurance policy to supplement an employer group plan. Be sure to check the group provisions carefully: How long does the group disability insurance coverage last? How much is the benefit? Does the disability insurance policy cover bonus and commission income? Will the coverage continue if you leave that employer?
                        
                        <br>A typical Individual Disability Income Protection plan starts to pay benefits after you have been off work for 1 to 6 months, and pays benefits for several years or until retirement age, depending on the policy.</p>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <div class="trusted-img">
                        <img src="assets/img/IndividualDisabilityInsurance.jpg" alt="Individual Disability Insurance">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- We Trusted End-->

    <!-- Team-profile Start -->
    <div class="team-profile team-padding">
        <div class="container">
            <h3>Dawar Insurance Agency offers free, comparative quotes on Individual Disability Insurance from multiple insurance carriers so you can get the best possible rate.
              <br> <br> Want to see how much we can save you? Just request a quote to find out.</h3>
         
        </div>
    </div>
    <!-- Team-profile End-->
  
  
    <!-- Request Back Start -->
    <div class="request-back-area section-padding30">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="request-content">
                        <h3>Start Saving Today</h3>
                        <p>We will get back to you within 24 hrs.</p>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <!-- Contact form Start -->
                    <div class="form-wrapper">
                        <form id="contact-form" action="#" method="POST">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box  mb-30">
                                        <input type="text" name="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="phone" name="email" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <input type="email" name="email" placeholder="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-box mb-30">
                                        <select name="select" id="select1" class="nice-select">
                                            <option value="">Type of Insurance</option>
                                            <option value="">Auto Insurance</option>
                                            <option value="">Home Insurance</option>
                                            <option value="">Othe Personal Insurance</option>
                                            <option value="">Business Insurance</option>
                                            <option value="">Life Insurance</option>
                                            <option value="">Health Insurance</option>
                                            <option value="">Group Benefits</option>                                          
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <button type="submit" class="send-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>     <!-- Contact form End -->
            </div>
        </div>
    </div>
    <!-- Request Back End -->
</main>
